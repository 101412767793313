// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';



*{
  font-family: "Manrope Regular", Arial, sans-serif;
}

/* Reduce the scrollbar width */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px; /* Decrease this value to make the scrollbar thinner */
  height: 7px;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(113, 113, 113, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


.custom-tooltip{
  background:#eeee;
  font-size: 12px;
} 

.txt-tooltips{
  font-weight: bold;
}

.p-text{
  padding: 10px;
}

.bg-top{
  background: #eeee;
}

.bg-bottom{
  background: white;
}


// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@font-face {
    font-family: 'Manrope Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Regular'), url('../font/manrope-cufonfonts-webfont/manrope-regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Manrope Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Thin'), url('../font/manrope-cufonfonts-webfont/manrope-thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Manrope Light';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Light'), url('../font/manrope-cufonfonts-webfont/manrope-light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Manrope Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Medium'), url('../font/manrope-cufonfonts-webfont/manrope-medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Manrope Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Semibold'), url('../font/manrope-cufonfonts-webfont/manrope-semibold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Manrope Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Manrope Bold'), url('../font/manrope-cufonfonts-webfont/manrope-bold.woff') format('woff');
    }
  
.manrope-thin {
    font-family: "Manrope Thin", sans-serif;
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}



.arrow-sidebar{
    background: white;
    border: 1px solid #EEEE;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgb(50 67 158 / 5%);
}

.select-small{
    position: absolute;
    border: none;
    background: #EEEE;
    /* border: 5px; */
    border-radius: 5px;
    padding:4px;
}

.character {
    border: 1px solid #EEEEEE;
    font-size: 50px;
    border-radius: 8px;
  
    /* light theme */
    color: #272729;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(50, 67, 158, 0.05);
    padding: 13px;
  }

.vi__container {
    display: flex;
    gap: 8px;
    height: 80px;
    width: 390px;
}


@media only screen and (max-width: 600px) {
    .character {
        border: 1px solid #EEEEEE;
        font-size: 20px;
        border-radius: 8px;
      
        /* light theme */
        color: #272729;
        background-color: white;
        box-shadow: 0px 4px 20px rgba(50, 67, 158, 0.05);
      
      }

      .vi__container {
        display: flex;
        gap: 8px;
        height: 80px;
        width: 300px;
    }
    
  }



//   image viewer
.lightbox {
	/* Hidden lightbox image */
    z-index: 99999;
	position: fixed;
	
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

	img {
		max-width: 90%;
		max-height: 80%;
		margin-top: 2%;
	}


}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 3em;
	color: #fff;
	text-decoration: none;
}

.thumbnail {
	max-width: 180px;
}

.thumbnail-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.disabled-date {
  color: grey;
}

.css-10begqm-MuiDivider-root{
  border-bottom-width: inherit !important;
}


.react-datepicker__input-container input{
  border: 1px solid #EEEE !important;
  padding: 5px 0px;
  width: 230px;
  padding-left: 45px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(50, 67, 158, 0.05);

}


//redirect loading
span[class^="dot-"]{
    opacity: 0;
  }
  .dot-one{
    animation: dot-one 2s infinite linear
  }
  .dot-two{
    animation: dot-two 2s infinite linear
  }
  .dot-three{
    animation: dot-three 2s infinite linear
  }
  @keyframes dot-one{
    0%{
      opacity: 0;
    }
    15%{
      opacity: 0;
    }
    25%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }
  
  @keyframes dot-two{
    0%{
      opacity: 0;
    }
    25%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }
  
  @keyframes dot-three{
    0%{
      opacity: 0;
    }
    50%{
      opacity: 0;
    }
    75%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }

  //animation circle status
  .blob-yellow {
    background:#EBAC32;
    border-radius: 50%;
    margin: 8px;
    height: 8px;
    width: 8px;
  
    box-shadow: 0 0 0 0 #EBAC32;
    transform: scale(1);
    animation: pulseYellow 2s infinite;
  }
  
  @keyframes pulseYellow {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #EBAC32;
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  .blob-blue{
    background:#32439E;
    border-radius: 50%;
    margin: 8px;
    height: 8px;
    width: 8px;
  
    box-shadow: 0 0 0 0 #32439E;
    transform: scale(1);
    animation: pulseBlue 2s infinite;
  }
  
  @keyframes pulseBlue {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #32439E;
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  .blob-red{
    background:#EB001B;
    border-radius: 50%;
    margin: 8px;
    height: 8px;
    width: 8px;
  
    box-shadow: 0 0 0 0 #EB001B;
    transform: scale(1);
    animation: pulseRed 2s infinite;
  }
  
  @keyframes pulseRed {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #EB001B;
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  :root {
 
    --color-design:     #0CB0E2;
    --color-research:   #0CB0E2;
    --radius:           240px;
  }
  
  
    .canvas,
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }
  
  
  
  .circles {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: rotate;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    backface-visibility: hidden;
    perspective: 1000;
  }
  
  .circle {
    position: relative;
    width: 1px;
    height: 1px;
    margin: calc( var(--radius) / 2 );
    border-radius: 50%;
    backface-visibility: hidden;
    perspective: 1000;
    opacity: .9;
  }
  
  .circle.design {
    background: var(--color-design);
    box-shadow: 0 0 var(--radius) var(--radius) var(--color-design);
    animation-name: float;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  
  .circle.research {
    background: var(--color-research);
    box-shadow: 0 0 var(--radius) var(--radius) var(--color-research);
    animation-name: float;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }
  
  @keyframes float {
    0%   { transform: translateX( calc( var(--radius) * 0.0 ) ) translateZ( 0 ) }
    20%  { transform: translateX( calc( var(--radius) * 0.3 ) ) translateZ( 0 ) } 
    40%  { transform: translateX( calc( var(--radius) * 1.0 ) ) translateZ( 0 ) }
    60%  { transform: translateX( calc( var(--radius) * 1.8 ) ) translateZ( 0 ) } 
    80%  { transform: translateX( calc( var(--radius) * 0.7 ) ) translateZ( 0 ) } 
    100% { transform: translateX( calc( var(--radius) * 0.0 ) ) translateZ( 0 ) }
  }
  
  
  @keyframes rotate {
    0%   { transform: rotate( 0deg )   translateZ( 0 ) }
    100% { transform: rotate( 360deg ) translateZ( 0 ) }
  }
  
  @media (max-width: 640px) {
    
    :root {
      --radius: 125px;
    }
    
    .overlay {
      background-size: 50%;
    }
    
  }
  



.custom-width-datepicker {
    width: 100px; /* Atur lebar input */
}